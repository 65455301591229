<template lang="pug">
div.mt-32.pb-24
  div.mb-16
    LivePreview(
      ref="preview"
      :creative="creative"
      :options="preview_options"
      height="500px"
      width="auto"
      :key="preview_options.force_placement_device"
      :poll="true"
      @versionchange="handleVersionChange"
    )
  CreativeSizes(:creative="creative" @reload="reloadDevice")
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import CreativeSizes from '@master/UI/CreativeSizes.vue';
import LivePreview from '@master/UI/LivePreview/LivePreview.vue';

export default {
  name: 'PreviewCustom',

  mixins: [CreativeTraits],

  components: {
    LivePreview,
    CreativeSizes,
  },

  props: {
    creative: Object,
  },

  computed: {
    preview_options() {
      return {
        screen_height: null,
        expandable: false,
        browser_bars: true,
        force_placement_device: this.creative?.settings?.preview_device ?? null,
      };
    },
  },

  methods: {
    reloadDevice() {
      if (this.$refs.preview != null) {
        this.$refs.preview.reloadDevice();
      }
    },

    handleVersionChange(version) {
      this.$emit('versionchange', version);
    },
  },
};
</script>
