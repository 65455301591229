import { showAssetUploadErrors } from '@helpers/Alerts';
import { confirmAssetUpload } from '@helpers/Confirms';
import { serializeAssetName } from '@helpers/Global';
import Upload from '@helpers/Upload';
import $http from '@master/Services/HttpService';

class Library {
  #assets = [];
  #files = [];
  #service = null;
  #library_id = null;
  #folder_id = null;
  #path = null;

  loading_label = 'Uploading';
  loading_percentage = null;

  set assets(assets) {
    this.#assets = assets;
  }

  set service(service) {
    this.#service = service;
    this.#library_id = service?.getLibraryID();
  }

  set folder_id(folder_id) {
    this.#folder_id = folder_id;
  }

  setPath(path) {
    this.#path = path;
  }

  loadMore() {
    if (this.#service) {
      return this.#service.loadMore();
    }
  }

  removeItems(ids) {
    if (this.#service) {
      return this.#service.removeItems(ids);
    }
  }

  fileChangeHandler(event, overwrite = false) {
    const { files, errors } = event;

    if (errors.length > 0) {
      if (!files?.length) {
        showAssetUploadErrors(errors);
        return;
      }
    }

    if (!files.length) {
      return;
    }

    this.#files = files;

    if (overwrite === true) {
      return this.import(true);
    }

    const duplicates = this.checkDuplicates();

    if (duplicates && duplicates.length > 0) {
      return this.confirmUpload(duplicates);
    } else {
      return this.import();
    }
  }

  checkDuplicates() {
    const duplicates = [];

    for (const file of this.#files) {
      const asset = this.findAssetByName(file.name);

      if (asset) {
        duplicates.push(asset.name);
      }
    }

    return duplicates;
  }

  async confirmAssetUpload(duplicates) {
    return await confirmAssetUpload(duplicates);
  }

  async confirmUpload(duplicates) {
    const response = await this.confirmAssetUpload(duplicates);

    if (response == null) {
      this.#files = [];
      return;
    }

    return this.import(response);
  }

  import(overwrite = false) {
    if (!this.#files?.length) {
      return;
    }

    for (const file of this.#files) {
      // hide loading if uploading from slot
      if (file._callback == null) {
        this.loading_percentage = 0;
      } else {
        // second param true tells that upload has started
        file._callback(null, true);
      }
    }

    const loadings = [];
    const uploads = [];

    for (const file of this.#files) {
      uploads.push(
        new Promise((resolve, reject) => {
          const index = loadings.push(0) - 1;
          const handler = new Upload(file);

          handler.upload(({ percentage, done, upload_id, error, msg }) => {
            loadings[index] = percentage;

            // hide loading if uploading from slot
            if (file._callback == null) {
              this.loading_percentage = Math.min(loadings.reduce((a, b) => a + b, 0) / loadings.length, 0.99);
            }

            if (msg != null) {
              this.loading_label = msg;
            }

            if (done) {
              resolve({
                _callback: file._callback,
                upload_id: upload_id,
                size: file.size,
              });
            }

            if (error?.message != null) {
              reject(error.message);
            }
          });
        }),
      );
    }

    return Promise.allSettled(uploads).then(promises => {
      const payload = {
        assets: [],
        overwrite,
      };

      let cb = null;
      const upload_errors = [];

      for (const promise of promises) {
        if (promise.status === 'fulfilled') {
          payload.assets.push(promise.value);
          cb = promise.value._callback;
        } else if (promise.status === 'rejected') {
          upload_errors.push(promise.reason);
        }
      }

      if (upload_errors.length > 0) {
        showAssetUploadErrors([], upload_errors);
      }

      return this.create(payload, cb);
    });
  }

  create(payload, cb) {
    let path = `assets/library/${this.#library_id}`;

    if (this.#path) {
      path = this.#path;
    } else if (this.#folder_id) {
      path += `/folder/${this.#folder_id}`;
    }

    return $http
      .post(path, payload)
      .then(response => {
        if (response.errors.length > 0) {
          showAssetUploadErrors([], response.errors);
        }

        if (this.#service && !payload.overwrite && response.assets) {
          this.#service.addItems(response.assets);
        }

        // callback used for slot uploads to get library asset id
        if (cb != null) {
          cb(response.assets[0]);
        }

        return response?.assets;
      })
      .catch(_ => {
        /** errors handled by notifications */
      })
      .finally(_ => {
        this.loading_percentage = null;
      });
  }

  uploadAsset(asset, service) {
    const library_id = service.getLibraryID();

    if (library_id == null) {
      return;
    }

    this.loading_percentage = 0;

    // mock loading percentage for better UX, maybe
    const interval = setInterval(() => {
      if (this.loading_percentage < 0.9) {
        this.loading_percentage += 0.1;
      } else {
        clearInterval(interval);
      }
    }, 200);

    let data = {};
    if (asset.original_asset_id) {
      data.originals = [asset.original_asset_id];
    } else if (asset.uri) {
      data.urls = [asset.uri];
    }

    $http
      .post(`assets/library/${library_id}`, data)
      .then(response => {
        if (service) {
          service.addItems(response.assets);
        }
      })
      .finally(() => {
        this.loading_percentage = null;
        clearInterval(interval);
      });
  }

  findAssetByName(filename) {
    // mock how how backend converts and renames asset
    filename = serializeAssetName(filename);

    for (const original of this.#assets) {
      if (filename === original.name) {
        return original;
      }
    }
  }
}

export default Library;
